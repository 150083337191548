<template>
  <Modal v-model="modal" sticky scrollable width="60%" :mask-closable="true" class="custom-modal-footer-block"
    @on-cancel="handleCancel">
    <template #header>
      <p class="title">调整复核人</p>
      <div class="modal-close" @click="handleCancel">关闭 <span>ESC</span></div>
    </template>
    <div class="type">
      <label for="">数据名称</label>
      <div class="type-item">
        {{ editObj.dataName }}
      </div>
    </div>
    <div class="type">
      <label for="">分类</label>
      <div class="type-item">
        <span>{{ editObj.first }}></span>
        <span>{{ editObj.second }}></span>
        <span>{{ editObj.third }}</span>
      </div>
    </div>
    <div class="custom-modal-table">
      <h3>以下成员拥有此分类数据目录的复核权限</h3>
      <ul>
        <li class="flex-center-between" v-for="(item, i) in permission" :key="i">
          <label for="">{{ item.username }}({{ item.account }})</label>
          <div class="btn-error-small btn" @click="handleRemove(i)">移除</div>
        </li>

        <li>
          <Select v-model="name" filterable class="width-300" @on-change="handleChange">
            <Option v-for="item in cityList" :value="item.account" :key="item.id">{{ item.username }}</Option>
          </Select>
          <div class="btn btn-default" @click="Add">添加</div>
        </li>
      </ul>
    </div>
    <!-- <div class="flex-center-center">
        <div class="btn btn-default" @click="handleSubmit">确定</div>
      </div> -->
    <template #footer>
      <div class="btn btn-default" @click="handleSubmit">确定</div>
    </template>
  </Modal>
</template>
    
    <script setup>
import { ref, watch, inject, onMounted, onBeforeUnmount } from "vue";
import bus from "@/utils/bus";
import { Message, Modal } from "view-ui-plus";
import qs from "qs";
let axios = inject("axios");
let modal = ref(false);
let selectObj = ref({}); //当前下拉框选择的对象
let editObj = ref({
  permission: [],
}); //修改的数据
const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });

//确定提交
const handleSubmit = () => {
  if (permission.value.length < 1) {
    return Modal.error({
      title: "提醒",
      content: "请至少添加一位成员后才能调整权限",
    });
  }
  let accounts = permission.value.map((item) => {
    return item.account;
  });
  let url = `/audit/review/edit`;
  let params = {
    reviews: accounts.join(","),
    dataId: editObj.value.id,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success({
        background: true,
        content: "权限调整成功",
      });

    } else {
      Message.error({
        background: true,
        content: res.data.message,
      });
    }
    modal.value = false;
    bus.emit("noticeReload");
  });
};

//取消
const handleCancel = () => {
  permission.value = [];
  editObj.value = {};
  modal.value = false;
  name.value = ''
};

let name = ref("");
let cityList = ref([]);
let permission = ref([]);

// 删除
const handleRemove = (i) => {
  permission.value.splice(i, 1);
  Message.success("删除成功");
};
//添加用户
const Add = () => {
  let account = selectObj.value.account;
  console.log(account);
  if (account == undefined || account == null) {
    return Message.error({
      background: true,
      content: "请选择要添加的成员后再进行添加",
      duration: 3,
    });
  }
  let isAuth = true;
  if (permission.value.length > 0) {
    permission.value.forEach((item) => {
      if (item.account == account) {
        isAuth = false;
        Message.error({
          background: true,
          content: "此用户已在列表中，请不要重复添加",
        });
      }
      name.value = "";
    });
  }
  if (isAuth) {
    permission.value.push(selectObj.value);
  }
};
//选择项发生变化
const handleChange = (account) => {
  cityList.value.forEach((item) => {
    if (item.account == account) {
      selectObj.value = item;
    }
  });
};

//获取用户列表
const getList = (query) => {
  let url = `/setting/user/list/enable`;
  let params = {
    pageOffset: 1,
    pageSize: 500,
    username: query,
    email: "",
    org: "",
  };

  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      cityList.value = data.content;
    } else {
      cityList.value = [];
    }
  });
};
onMounted(() => {
  //获取修改来的数据
  bus.on("editId", (row) => {
    editObj.value = { ...row };
    // row.reviewer
    let reviewerArr = row.reviewer.split(';')
    permission.value = row.reviewer.split(';').map((item, i) => {
      return {
        username: reviewerArr[i],
        account: row.reviewerEmail[i]
      }
    })
  });
  getList("");
});
onBeforeUnmount(() => {
  bus.all.delete("editId");
});
</script>
    
    <style lang="scss" scoped>
.type {
  margin: 30px 0;
  display: flex;
  label {
    display: inline-block;
    width: 80px;
    font-size: 14px;
    color: #555555;
    line-height: 33px;
  }
  .type-item {
    font-size: 14px;
    color: #555555;
    line-height: 33px;
  }
}
.question {
  h3 {
    color: #555555;
  }
}
.table {
  li {
    margin: 10px 0;
  }
}
</style>