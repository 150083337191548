<template>
  <div class="wrap-content">
    <Header  />
    <important />
  </div>
</template>
  
  <script setup>
import { ref, inject, provide, onMounted } from "vue";
import common from "./common.vue";
import important from "./important.vue";
import Header from "./components/Header.vue";
// import Footer from "@/components/footer.vue";
// import noData from "./components/noData.vue";
import bus from "@/utils/bus.js";
let tab = ref(0);
function handleNav(val) {
  tab.value = val;
}

let unCount = ref(0);
let count = ref(0);
bus.on("count", (val) => {
  count.value = val;
});
bus.on("unCount", (val) => {
  unCount.value = val;
});
onMounted(() => {
});
</script>
  
  <style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
}
.noData {
  height: calc(100vh - 100px);
}
</style>