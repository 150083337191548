<template>
  <Modal
    v-model="modal"
    sticky
    scrollable
    :mask-closable="true"
    width="60%"
    class="custom-modal"
    @on-cancel="handleCancel"
  >
    <template #header>
      <p class="title">提交失败</p>
      <div class="modal-close" @click="handleCancel">关闭 <span>ESC</span></div>
    </template>
    <div class="errorInfo question">
      <Collapse class="custom-collapse" simple>
        <Panel :name="item" v-for="(item, i) in errorData.data" :key="i">
          <span class="sub-title">{{ errorData.title }}</span>
          <template #content>
            <div class="question-item-content">
              <P>
                {{ item }}
              </P>
            </div>
          </template>
        </Panel>
      </Collapse>
    </div>
  </Modal>
</template>

<script setup>
import { ref, onMounted, inject, watch } from "vue";
import bus from "@/utils/bus";
let modal = ref(false);
let errorData = ref({
  title: "",
  data: [],
});
const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });

const handleCancel = () => {
  modal.value = false;
  errorData.value = {
    title: "",
    data: [],
  };
};
onMounted(() => {
  bus.on("errorData", (data) => {
    console.log(data)
    modify();
    errorData.value = data;
  });
});
</script>

<style lang="scss" scoped>
.custom-collapse {
  border: none;
  :deep(.ivu-collapse-header) {
    background-color: #fff;
    border-bottom: none !important;
    .update {
      float: right;
      padding-right: 20px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      letter-spacing: 0px;
      color: #555555;
    }
    .sub-title {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      letter-spacing: 0px;
      color: #111111;
    }
    .ivu-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  :deep(.ivu-collapse-item) {
    border-top: none;
  }
  :deep(.ivu-collapse-item:first-child) {
    margin-top: 10px;
  }
}
.question {
  //   border: none !important;
  padding-bottom: 50px;
  max-height: 60vh;
  overflow-y: auto;
}
.question-item-content {
  p {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #555555;
  }
}
.title {
  font-family: PingFangSC-Medium;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #111111;
}
.questions {
  display: flex;
  align-items: flex-start;
  width: 100%;
  > label {
    width: 120px;
    font-size: 14px;
    color: #555555;
    line-height: 30px;
    white-space: nowrap;
  }
  .question-item {
    width: calc(100% - 120px);
    // margin-left: 10px;
    p {
      font-size: 14px;
      color: #555555;
      line-height: 30px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      span:first-child {
        padding-right: 10px;
      }
      span:last-child {
        width: calc(100% - 20px);
        display: inline-block;
        word-break: break-all;
      }
    }
  }
}
.bold {
  text-decoration: underline;
  color: #3d55a8;
  padding: 0 3px;
  cursor: pointer;
}
</style>