<template>
  <div class="main">
    <div class="search">
      <ul>
        <li>
          <Input placeholder="请输入名称" v-model="name" class="width-300" @on-enter="handleSearch" />
        </li>
        <li>
          <el-cascader :props="props" :options="dataTypeList" v-model="dataType" filterable class="width-300"
            placeholder="请选择数据分类" />
        </li>
        <li>
          <Input placeholder="所属机构" v-model="submitInst" class="width-200" @on-enter="handleSearch" />
        </li>
        <li>
          <Input placeholder="填报人" v-model="submitter" class="width-200" @on-enter="handleSearch" />
        </li>
        <li>
          <div class="btn search-btn flex-center-center" @click="handleSearch">
            <div class="img"></div>
          </div>
          <div class="btn reset-btn flex-center-center" @click="handleReset" v-if="
              name != '' ||
              dataType.length > 0 ||
              submitInst != '' ||
              submitter != ''
            ">
            <div class="img"></div>
          </div>
        </li>
        <li class="static">
          <span>共 <span class="bold">{{ total }}</span>项</span>
          <span v-if="selection.length > 0">已选 <span class="bold">{{ selection.length }}</span>项</span>
        </li>
      </ul>
      <div>
        <Button class="margin-right-10 btn-simple" @click="handleThrough">批量通过</Button>
        <Button class="btn-simple" @click="ExportList">导出列表</Button>
      </div>
    </div>
    <div class="table" v-if="total > 0">
      <Table :columns="columns" :data="tableData" border @on-selection-change="changeAll">
        <template #dataName="{ row }">
          <Tooltip :content="row.dataName" placement="top" max-width="300" theme="light" :transfer="true">
            <span class="name">{{ row.dataName }}</span>
          </Tooltip>
        </template>
        <template #status="{ row }">
          <span v-if="row.state == 0" class="draft">草稿</span>
          <span v-if="row.state == 1" class="examine">复核中</span>
          <span v-if="row.state == 2" class="nothrough">未通过</span>
        </template>
        <template #level="{ row }">
          <span>{{ row.level }}</span>
          <div class="rate" v-if="row.level == '核心数据'">
            <img src="../../assets/img/级别.png" alt="" />
            <img src="../../assets/img/级别.png" alt="" />
            <img src="../../assets/img/级别.png" alt="" />
          </div>
          <div class="rate" v-if="row.level == '重要数据'">
            <img src="../../assets/img/级别.png" alt="" />
            <img src="../../assets/img/级别.png" alt="" />
          </div>
          <div class="rate" v-if="row.level == '一般数据'">
            <img src="../../assets/img/级别.png" alt="" />
          </div>
        </template>
        <template #dataSize="{ row }">
          <span>{{ row.dataSize }}GB</span>
        </template>
        <template #type="{ row }">
          <template v-if="row.level == '重要数据' || row.level == '核心数据'">
            <Tooltip placement="top" max-width="300" theme="light">
              <template #content>
                <span> {{ row.first }}></span>
                <span>{{ row.second }}></span>
                <span>{{ row.third }}</span>
              </template>
              <span> {{ row.first }}></span>
              <span>{{ row.second }}></span>
              <span>{{ row.third }}</span>
            </Tooltip>
          </template>
          <template v-if="row.level == '一般数据'">
            <Tooltip placement="top" max-width="300" theme="light">
              <template #content>
                <span v-for="(each, n) in row.dataType" :key="n">
                  <template v-if="n != row.dataType.length - 1">
                    <span> {{ row.first }}></span>
                    <span v-for="(m, t) in each" :key="t">
                      <span v-if="t != each.length - 1">{{ m }}></span>
                      <span v-else>{{ m }}</span> </span>；
                  </template>
                  <template v-else>
                    <span> {{ row.first }}></span>
                    <span v-for="(m, t) in each" :key="t">
                      <span v-if="t != each.length - 1">{{ m }}></span>
                      <span v-else>{{ m }}</span>
                    </span>
                  </template>
                </span>
              </template>
              <span v-for="(each, n) in row.dataType" :key="n">
                <template v-if="n != row.dataType.length - 1">
                  <span> {{ row.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span> </span>；
                </template>
                <template v-else>
                  <span> {{ row.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span>
                  </span>
                </template>
              </span>
            </Tooltip>
          </template>
        </template>
        <template #action="{ row }">
          <!-- @on-select="handleAction(row)"  -->
<!--          <Select v-model="row.operation" :disabled="row.SubmissionYear ==currentYear?false:true"-->
<!--            @on-select="((val)=>{handleAction(val, row)})" :transfer="true">-->
<!--            <Option value="复核" v-if="row.status == 1 && row.SubmissionYear ==currentYear">复核</Option>-->
<!--            <Option value="调整复核人" v-if="role == 'admin'&& row.SubmissionYear 年度数
据目录填报==currentYear">调整复核人</Option>-->
<!--          </Select>-->
          <Select v-model="row.operation"
                  @on-select="((val)=>{handleAction(val, row)})" :transfer="true">
            <Option value="复核" v-if="row.status == 1">复核</Option>
            <Option value="调整复核人" v-if="role == 'admin'">调整复核人</Option>
          </Select>
        </template>
      </Table>
      <!-- <div class="add" @click="handleModal"><Icon type="md-add-circle" /></div> -->

      <div class="page" v-if="total > 50">
        <Page :total="total" show-elevator show-total @on-change="changePage" :page-size="pageSize" />
      </div>
    </div>
    <div class="noData" v-if="total == 0">
      <noData />
    </div>
    <!-- 更多列 -->
    <Modal v-model="modal" sticky scrollable :mask-closable="true" width="90%" class="custom-modal"
      @on-cancel="modal = false">
      <template #header>
        <div class="title">更多列</div>
        <div class="modal-close" @click="modal = false">
          关闭
          <span>ESC</span>
        </div>
      </template>
      <Columns v-if="isOpen" />
    </Modal>
    <classifyModal ref="modal1" />
    <errorModal ref="errorRef" />
  </div>
  <Footer />
</template>

  <script setup>
import {
  ref,
  reactive,
  inject,
  onMounted,
  nextTick,
  resolveComponent,
  onBeforeUnmount,
} from "vue";

import { Message } from "view-ui-plus";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
import Footer from "@/components/footer.vue";
import noData from "./components/noData.vue";
import { Modal } from "view-ui-plus";
import qs from "qs";
import DownFiles from "@/utils/dowFile";
import Columns from "./columns.vue";
import classifyModal from "./components/modal.vue";
import errorModal from "@/components/errorModal.vue";
let modal = ref(false);
let name = ref("");
let actionType = ref("");
let dataType = ref([]);
let submitInst = ref("");
let submitter = ref("");
let dataTypeList = ref([]);
let columns = ref([
  {
    type: "selection",
    width: 50,
    align: "center",
    fixed: "left",
  },
  {
    title: "数据名称",
    slot: "dataName",
  },

  {
    title: "数据分类",
    slot: "type",
  },
  {
    title: "数据级别",
    slot: "level",
    align: "center",
  },
  {
    title: "数据大小",
    slot: "dataSize",
    align: "center",
  },
  {
    title: "所属机构",
    key: "submitInst",
    align: "center",
  },
  {
    title: "填报人",
    key: "submitter",
    align: "center",
  },
  {
    title: "提交日期",
    key: "dataTime",
    align: "center",
  },
  {
    title: "操作",
    slot: "action",
    align: "center",
    width: 150,
    fixed: "right",
    renderHeader: (h, params) => {
      return h("div", [
        h("span", "操作"),

        h(resolveComponent("Icon"), {
          class: "ivu-icon-md-add-circle",
          style: {
            color: "#3d55a8",
            fontSize: "18px",
            paddingLeft: "10px",
          },
          onClick: () => {
            handleModal();
          },
        }),
      ]);
    },
  },
]);
let tableData = ref([]);
let page = ref(1);
let pageSize = ref(50);
let total = ref(0);
let selection = ref([]);
let router = useRouter();

let actionList = [
  {
    label: "编辑",
    value: "编辑",
  },
  {
    label: "提交",
    value: "提交",
  },
  {
    label: "删除",
    value: "删除",
  },
  {
    label: "复制",
    value: "复制",
  },
];
const props = {
  multiple: false,
  checkStrictly: true,
};

function changePage(val) {
  page.value = val;
  getTableData();
}
let axios = inject("axios");
const getTableData = () => {
  let url = `/audit/list`;
  let params = {
    pageOffset: page.value,
    pageSize: pageSize.value,
    dataName: name.value.replace(/%/g, '%25'),
    submitInst: submitInst.value,
    submitter: submitter.value,
    // level: "",
  };
  if (dataType.value.length > 0) {
    params.first = dataType.value[0];
    params.second = dataType.value[1];
    params.third = dataType.value[2];
  }
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;

      tableData.value = data.content.map((item) => {
        switch (item.state) {
          case 0:
            item.state = "草稿";
            break;
          case 1:
            item.state = "复核中";
            break;
          case 2:
            item.state = "未通过";
            break;
          case 3:
            item.state = "已通过";
            break;
        }
        return {
          ...item,
          operation: '',
          second: Array.isArray(item.second)
            ? item.second.join(";")
            : item.second,
          third: Array.isArray(item.third) ? item.third.join(";") : item.third,
          reviewer: Array.isArray(item.reviewer)
            ? item.reviewer.join(";")
            : item.reviewer,
          // SubmissionYear: item.createTime.split('-')[0],
          SubmissionYear: item.year
        };
      });

      console.log(2222, tableData.value)
      total.value = data.count;
      //通知父组件总个数
      bus.emit("count", total.value);
    }
  });
};
function changeAll(arr) {
  selection.value = arr.map((item) => {
    return item.id;
  });
}
function handleSearch() {
  selection.value = []
  page.value = 1;
  getTableData();
}
//重置
const handleReset = () => {
  name.value = "";
  dataType.value = [];
  submitInst.value = "";
  submitter.value = "";
  handleSearch();
};
const getLevelData = () => {
  let url = `/content/index`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      dataTypeList.value = res.data.data;
    }
  });
};

//导出列表
const ExportList = () => {

  let params = {
    dataIds: "",
  };
  if (selection.value.length > 0) {
    params.dataIds = selection.value.join(",");
  }
  params = {
    ...params,
    dataName: name.value,
    // level: "重要数据",
    submitInst: submitInst.value,
    submitter: submitter.value,
    first: '',
    second: '',
    third: '',
  };
  if (dataType.value.length > 0) {
    params.first = dataType.value[0];
    params.second = dataType.value[1];
    params.third = dataType.value[2];
  }

  axios.post(`/audit/exportjudge`, qs.stringify(params)).then((res2) => {
    if (res2.data.code == 200) {
      axios.post('/audit/export', qs.stringify(params), { responseType: "blob" }).then((res) => {
        if (res.status == 200) {
          const blob = res.data;
          const content = res.headers["content-disposition"];
          const fileName = content && content.split(";")[1].split("filename=")[1];
          DownFiles(blob, fileName);
        } else {
          Message.error(res.data.message);
        }
      })
    } else {
      Message.error({
        background: true,
        content: res2.data.message,
        duration: 3
      })
    }
  })




};

let errorRef = ref(null);
//批量提交
const handleThrough = () => {
  if (selection.value.length == 0) {
    return Message.error({
      background: true,
      content: "请选择数据后再进行操作",
      duration: 3,
    });
  }
  let url = `/audit/batch`;
  let params = {
    dataResourceIds: selection.value.join(","),
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success("批量通过成功");
      handleSearch();
    } else {
      let obj = {
        title: res.data.message,
        data: res.data.data,
      };
      bus.emit('errorData', obj);
    }
  });
};


//复核
const auditData = (row) => {
  router.push({
    name: "dataDetails",
    query: {
      id: row.id,
      level: 1,
    },
  });
};

let isOpen = ref(false);
//获取默认的列
const getColumns = () => {
  let url = `/general/display/type`;
  let params = {
    form: "important",
    type: "audit",
  };
  axios.get(url, { params }).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data != null) {
        let newColumn = [];
        if (data.fields && data.fields.length > 0) {
          newColumn.unshift({
            type: "selection",
            width: 70,
            align: "center",
            fixed: "left",
            resizable: true,
          });
          let includeArr = ["type", "dataSize", "action", "level", "dataName"];
          data.fields.forEach((item) => {
            let obj = {};
            if (includeArr.includes(item.key)) {
              obj = {
                title: item.label,
                slot: item.key,
                // width:200
                minWidth: 200,
                resizable: true,
              };
            } else {
              obj = {
                ...item,
                title: item.label,
                // width: 200
                minWidth: 150,
                resizable: true,
              };
            }
            newColumn.push(obj);
          });
          newColumn.push({
            title: "操作",
            slot: "action",
            align: "center",
            width: 120,
            fixed: "right",
            resizable: true,
            renderHeader: (h, params) => {
              return h("div", [
                h("span", "操作"),

                h(resolveComponent("Icon"), {
                  class: "ivu-icon-md-add-circle",
                  style: {
                    color: "#3d55a8",
                    fontSize: "18px",
                    paddingLeft: "10px",
                  },
                  onClick: () => {
                    handleModal();
                  },
                }),
              ]);
            },
          });
          columns.value = newColumn;

          console.log(11111111111111111, columns.value)
        }
      }
    }
  });
};

// 获取用户权限
let role = ref("");
const getRole = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    role.value = user.userInfo.role;
  }
};
//操作
const handleAction = (name, row) => {
  if (name.value == "复核") {
    auditData(row);
  } else if (name.value == "调整复核人") {
    assignPermission(row);
  }

};

//分配审核员

let modal1 = ref();
let actionObj = ref({});
const assignPermission = (row) => {
  openModal();
  bus.emit("editId", row);
};
//打开modal
const openModal = () => {
  modal1.value.modify();
};

const handleModal = () => {
  isOpen.value = true;
  modal.value = true;
  nextTick(() => {
    bus.emit("source", "audit");
  });
};
let currentYear=ref(null);
//获取系统年度时间
const getStatusInfo = () => {
  let url = `/setting/getFillState`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      currentYear.value=data.year;
    }
  });
};
onMounted(() =>{
        getStatusInfo();
  getRole();
  getLevelData();
  getColumns();
  handleSearch();
  //通知更多列弹框关闭
  bus.on("closeModal", () => {
    modal.value = false;
  });
  bus.on("noticeReload", () => {
    getColumns();
    handleSearch();
  });
});
onBeforeUnmount(() => {
  bus.all.delete("closeModal");
  bus.all.delete("noticeReload");
});
</script>
<style lang="scss" scoped>
@import "./examineList.scss";
.add {
  color: #3d55a8;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-5px);
}
</style>
